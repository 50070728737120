<template>
    <div class="traffic-statistics">
        <app-loader v-if="loading"></app-loader>

        <div class="filter">
            <h2 class="heading">Chart</h2>

            <app-input-datepicker v-show="period == 'day'"
                :value="date"
                label="Day"

                :disabled="loading"

                @change="changeDate"
            ></app-input-datepicker>
            
            <app-dropdown-select
                :value="period"
                :options="periods"

                label="View:"
                :label-inline="true"

                key-value="key"
                key-title="title"

                @change="changePeriod"
            ></app-dropdown-select>
        </div>

        <g-chart v-show="!is_empty"
            :data="statistics"

            type="ColumnChart"
            :settings="settings"
            :options="options"

            :events="events"
            :createChart="createChart"

            @ready="onChartReady"
        ></g-chart>
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import appLoader from '@/components/app-loader'
import appDropdownSelect from '@/components/app-dropdown-select'
import appInputDatepicker from '@/components/app-input-datepicker'

export default {
    props: {
        data: { type: Array, reqired: true },

        date: { type: Date, reqired: true },
        period: { type: String, reqired: true },
        periods: { type: Array, reqired: true },

        loading: { type: Boolean, default: false },
    },

    components: {
        GChart,

        appLoader,
        appDropdownSelect,
        appInputDatepicker,
    },

    data() {
        return {
            chart: null,
            charts_lib: null,

            settings: {
                packages: ['bar']
            },

            events: {
                select: () => {
                    if (this.period == 'month') {
                        const selection = this.chart.getSelection()[0]
                        const row = this.data[ selection.row ]

                        if ('date' in row) {
                            this.changeDate(row.date)
                        }
                    }
                },
            },
        }
    },

    methods: {
        createChart(el, google) {
            this.chart = new google.charts.Bar(el)

            return this.chart
        },

        onChartReady(chart, google) {
            this.charts_lib = google
        },

        changePeriod(period) {
            this.$emit('change-period', { period, date: this.date })
        },

        changeDate(date) {
            this.$emit('change-period', { period: 'day', date })
        },
    },

    computed: {
        statistics() {
            let statistics = [['Time', 'Traffic']]

            for (let i = 0, len = this.data.length; i < len; i++) {
                const item = this.data[i]

                statistics.push([item.time, item.traffic])
            }

            return statistics
        },

        options() {
            let options = null

            if (this.charts_lib) {
                options = this.charts_lib.charts.Bar.convertOptions({
                    height: 536,

                    bars: 'vertical',

                    series: {
                        0: {
                            color: '#e60000',
                            visibleInLegend: false,
                        },
                    },

                    legend: 'none',

                    vAxis: {
                        baselineColor: '#ccc',
                        format: '#,### MB',
                        gridlines: {
                            color: '#cccc'
                        },
                        textStyle: {
                            color: '#999'
                        },
                        title: '',
                    },
                    hAxis: {
                        textStyle: {
                            color: '#999'
                        },
                        title: '',
                    },
                    
                    tooltip: {
                        isHtml: true,
                        trigger: 'none',
                        textStyle: {
                            color: 'green'
                        },
                    },
                })
            }

            return options
        },

        is_empty() {
            return !this.data.length
        },
    },
}
</script>

<style lang="scss">
.traffic-statistics {
    padding: 16px 24px 24px;
    background: var(--color-component-bg-primary);
    border-radius: $border-radius-primary;
    box-shadow: var(--box-shadow-secondary);
    position: relative;
    overflow: hidden;

    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;

        &:only-child {
            margin: 0;
        }

        h2 {
            flex-grow: 1;
        }

        .app-input-datepicker {
            width: auto;
            margin-right: 10px;
        }

        .app-dropdown-select {
            font-size: 18px;
        }
    }
}
</style>