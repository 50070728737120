<template>
    <div id="page-prepay-mobile-service-statistics">
        <app-loader v-if="loading.service" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'prepay-mobile-services' }">Back to PrePay Mobile Services</router-link>

                    <div class="header-info">
                        <h1 class="name">{{ customer_fullname }}</h1>
                        <div class="status">Account status: <span class="tag" :class="[customer_status.value]">{{ customer_status.text }}</span></div>
                    </div>
                </div>
            </div>

            <div class="row details">
                <div class="col-6 col-mob-12">
                    <app-table-details
                        :rows="rows"
                        :data="details"

                        title="Mobile service information"
                    />
                </div>
                
                <div class="col-12">
                    <traffic-statistics
                        :data="traffic.list"

                        :date="traffic.date"
                        :period="traffic.period"
                        :periods="traffic.periods"

                        :loading="loading.traffic"

                        @change-period="loadStatistics"  
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

import trafficStatistics from './components/traffic-statistics'

const TEST_STATISTICS_DAY = [
    { time: '00:00', traffic: 12248 },
    { time: '01:00', traffic:  1127 },
    { time: '02:00', traffic:  1803 },
    { time: '03:00', traffic:     0 },
    { time: '04:00', traffic:     0 },
    { time: '05:00', traffic:     0 },
    { time: '06:00', traffic:     0 },
    { time: '07:00', traffic:     0 },
    { time: '08:00', traffic:     0 },
    { time: '09:00', traffic:  2705 },
    { time: '10:00', traffic:  3607 },
    { time: '11:00', traffic:  5486 },
    { time: '12:00', traffic:  8341 },
    { time: '13:00', traffic:  6162 },
    { time: '14:00', traffic:  1803 },
    { time: '15:00', traffic:   902 },
    { time: '16:00', traffic:   451 },
    { time: '17:00', traffic:  4208 },
    { time: '18:00', traffic:  6162 },
    { time: '19:00', traffic:  8942 },
    { time: '20:00', traffic: 21642 },
    { time: '21:00', traffic: 25324 },
    { time: '22:00', traffic: 23069 },
    { time: '23:00', traffic: 14503 },
]

const TEST_STATISTICS_MONTH = [
    { time: '01/07', traffic: 23937, date: new Date('2020-07-01') },
    { time: '02/07', traffic: 21890, date: new Date('2020-07-02') },
    { time: '03/07', traffic: 27795, date: new Date('2020-07-03') },
    { time: '04/07', traffic: 19134, date: new Date('2020-07-04') },
    { time: '05/07', traffic: 11417, date: new Date('2020-07-05') },
    { time: '06/07', traffic:  7008, date: new Date('2020-07-06') },
    { time: '07/07', traffic: 16535, date: new Date('2020-07-07') },
    { time: '08/07', traffic:  3543, date: new Date('2020-07-08') },
    { time: '09/07', traffic: 19134, date: new Date('2020-07-09') },
    { time: '10/07', traffic:  8268, date: new Date('2020-07-10') },
    { time: '11/07', traffic: 21890, date: new Date('2020-07-11') },
    { time: '12/07', traffic: 23937, date: new Date('2020-07-12') },
    { time: '13/07', traffic: 21339, date: new Date('2020-07-13') },
    { time: '14/07', traffic: 16535, date: new Date('2020-07-14') },
    { time: '15/07', traffic: 12835, date: new Date('2020-07-15') },
    { time: '16/07', traffic:  9606, date: new Date('2020-07-16') },
    { time: '17/07', traffic: 19134, date: new Date('2020-07-17') },
    { time: '18/07', traffic: 27795, date: new Date('2020-07-18') },
    { time: '19/07', traffic: 19134, date: new Date('2020-07-19') },
    { time: '20/07', traffic: 29370, date: new Date('2020-07-20') },
    { time: '21/07', traffic: 21339, date: new Date('2020-07-21') },
    { time: '22/07', traffic:  8268, date: new Date('2020-07-22') },
    { time: '23/07', traffic: 23937, date: new Date('2020-07-23') },
    { time: '24/07', traffic: 29370, date: new Date('2020-07-24') },
    { time: '25/07', traffic: 26850, date: new Date('2020-07-25') },
    { time: '26/07', traffic:  6457, date: new Date('2020-07-26') },
    { time: '27/07', traffic:  3228, date: new Date('2020-07-27') },
    { time: '28/07', traffic: 19134, date: new Date('2020-07-28') },
    { time: '29/07', traffic: 21339, date: new Date('2020-07-29') },
    { time: '30/07', traffic:  4173, date: new Date('2020-07-30') },
]

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,

        trafficStatistics,
    },

    data() {
        return {
            service: null,
            customer: null,

            traffic: {
                list: [],
                date: new Date(),
                period: 'day',
                periods: [
                    { key: 'day',   title: 'Day'   },
                    { key: 'month', title: 'Month' },
                ],
            },

            rows: [
                { key: 'name',       title: 'Name'                              },
                { key: 'type',       title: 'Service Type'                      },
                { key: 'identifier', title: 'Service Identifier'                },
                { key: 'status',     title: 'Status',            type: 'status' },
            ],

            loading: {
                service: false,
                customer: false,
                traffic: false,
            },

            TEST_STATISTICS_DAYS: {},
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadServiceDetails()
            this.loadStatistics()
        },

        loadServiceDetails() {
            this.loading.service = true

            this.$store.dispatch('getMobileServiceByUUID', { uuid: this.service_uuid }).then(service => {
                this.service = service

                this.loadCustomerInfo()

                this.loading.service = false
            }).catch(error => {
                console.log('Error getMobileServiceByUUID', this.service_uuid)
                console.dir(error)
                
                this.$router.push({ name: 'prepay-mobile-services', replace: true })
            })
        },

        loadCustomerInfo() {
            if (this.customer_uuid) {
                this.loading.customer = true

                this.$store.dispatch('getCustomerByUUID', { uuid: this.customer_uuid }).then(customer => {
                    this.customer = customer

                    this.loading.customer = false
                }).catch(error => {
                    console.log('Error getCustomerByUUID', this.customer_uuid)
                    console.error(error)

                    this.customer = null

                    this.loading.customer = false
                })
            }
        },

        loadStatistics(params) {
            this.loading.traffic = true
            
            if (params) {
                this.traffic.date = params.date
                this.traffic.period = params.period
            }

            setTimeout(() => {
                if (this.traffic.period == 'day') {
                    const date = this.traffic.date.toLocaleDateString()

                    if (!(date in this.TEST_STATISTICS_DAYS)) {
                        this.TEST_STATISTICS_DAYS[date] = params ? this.testRandomDayTraffic() : TEST_STATISTICS_DAY
                    }

                    this.traffic.list = this.TEST_STATISTICS_DAYS[date]
                } else {
                    this.traffic.list = TEST_STATISTICS_MONTH
                }

                this.loading.traffic = false
            }, 500)
        },

        testRandomDayTraffic() {
            return TEST_STATISTICS_DAY.map(item => ({ time: item.time, traffic: Math.floor(Math.random() * 10000) }))
        },
    },

    watch: {
        service_uuid() {
            this.loadServiceDetails()
        },
    },

    computed: {
        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        customer_uuid() {
            return this.service ? this.service.CustomerUUID : null
        },
        customer_fullname() {
            const fullname = this.customer
                ? [this.customer.FirstName, this.customer.LastName].join(' ').trim()
                : ''

            return fullname.length ? fullname : '?'
        },
        customer_status() {
            return this.customer ? (
                this.customer.Active ? {
                        text: 'Active',
                        value: 'success',
                    } : {
                        text: 'Inactive',
                        value: 'danger',
                    }
                ) : {
                    text: 'Loading',
                    value: 'info',
                }
        },

        details() {
            return this.service ? {
                name: this.service.Name || '?',
                type: this.service.ServiceType || '?',
                identifier: this.service.ServiceIdentifier || '?',
                status: this.service.Status ? {
                    status: this.service.Status.toLowerCase(),
                    text: this.service.Status,
                } : {
                    status: '?',
                    text: 'info',
                },
            } : {
                name: '-',
                type: '-',
                identifier: '-',
                status: {
                    status: 'Loading',
                    text: 'info',
                },
            }
        },
    },
}
</script>

<style lang="scss">
#page-prepay-mobile-service-statistics {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 40px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-left: 32px;

            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-default);

            .tag {
                margin-left: 16px;
                padding: 4px 12px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;

                &.success {
                    background-color: var(--color-success-bg);
                    color: var(--color-success);
                }

                &.info {
                    background-color: var(--color-info-bg);
                    color: var(--color-info);
                }

                &.warning {
                    background-color: var(--color-warning-bg);
                    color: var(--color-warning);
                }

                &.danger {
                    background-color: var(--color-danger-bg);
                    color: var(--color-danger);
                }
            }
        }
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }
}

@media (max-width: $tablet-size) {
    #page-prepay-mobile-service-statistics {
        .header-info {
            width: 100%;
            margin-bottom: 48px;

            .name {
                font-size: 48px;
                line-height: 56px;
            }

            .status {
                font-size: 14px;
                line-height: 24px;
                margin-left: 16px;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-prepay-mobile-service-statistics {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }

            .status {
                margin: 8px 0 0;
                justify-content: flex-start;

                .tag {
                    margin-left: 12px;
                    line-height: 20px;
                }
            }
        }

        .details {
            .col-mob-12 {
                margin-top: 15px;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>